<template>
  <div>
    <el-button type="text" @click="dialogVisible = true">添加码表</el-button>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      Code名：
      <el-select v-model="codes.codeName" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      Code值：
      <el-input
        style="width: 180px"
        size="mini"
        v-model="codes.codeValue"
      ></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCode">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 列表展示 -->
    <el-card>
      <el-table :data="houseList" border style="width: 100%">
        <!-- <el-table-column prop="id" label="CODE" width="280px">
        </el-table-column> -->
        <el-table-column prop="codeName" label="码表名"> </el-table-column>
        <el-table-column prop="codeValue" label="码表值"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="deleteHouse(scope.row.id)"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination
        style="margin-top: 20px"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[5, 10, 25, 20]"
        @current-change="pageNumChange"
        @size-change="pageSizeChange"
      >
      </el-pagination>
    </el-card>
    <!-- 更新房源模态框 -->
    <el-dialog title="编辑房源" :visible.sync="editFormVisible">
      <el-form :model="editForm" label-width="100px">
        <el-form-item label="所在省">
          <!-- 所在省 -->
          <el-select v-model="editForm.province" disabled>
            <el-option
              v-for="area in area.provinceList"
              :key="area.name"
              :label="area.name"
              :value="area.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 所在市 -->
        <el-form-item label="所在市">
          <el-select v-model="editForm.city" disabled>
            <el-option
              v-for="area in editArea.cityList"
              :key="area.name"
              :label="area.name"
              :value="area.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 所在区 -->
        <el-form-item label="所在区">
          <el-select v-model="editForm.area" disabled>
            <el-option
              v-for="area in editArea.areaList"
              :key="area.name"
              :label="area.name"
              :value="area.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 租赁方式 -->
        <el-form-item label="方式">
          <el-radio-group v-model="editForm.rentMode" size="medium">
            <el-radio-button
              v-for="dict in dict.rentModeList"
              :key="dict.name"
              :label="dict.value"
              >{{ dict.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <!-- 户型 -->
        <el-form-item label="户型">
          <el-radio-group v-model="editForm.houseType" size="medium">
            <el-radio-button
              v-for="dict in dict.houseTypeList"
              :key="dict.name"
              :label="dict.value"
              >{{ dict.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <!-- 朝向 -->
        <el-form-item label="朝向">
          <el-radio-group v-model="editForm.direction" size="medium">
            <el-radio-button
              v-for="dict in dict.directionList"
              :key="dict.name"
              :label="dict.value"
              >{{ dict.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <!-- 租金 -->
        <el-form-item label="租金">
          <el-input v-model="editForm.rental"></el-input>
        </el-form-item>
        <!-- 地址 -->
        <el-form-item label="地址">
          <el-input v-model="editForm.address" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: "XH",
          label: "型号",
        },
        {
          value: "CL",
          label: "材料",
        },
        {
          value: "YS",
          label: "颜色",
        },
        {
          value: "ZSL",
          label: "折射率",
        },
        {
          value: "MC",
          label: "膜层",
        },
        {
          value: "PJLX",
          label: "配镜类型",
        },
      ],
      codes: {
        codeValue: "",
        codeName: "",
      },

      rentModeList: [],
      dialogVisible: false,
      houseList: [] /* 列表数据 */,
      pageNum: 1 /* 当前页 */,
      pageSize: 5 /* 每页显示记录数 */,
      total: 0 /* 数据总量 */,
      queryForm: {
        /* 查询条件 */ province: "",
        city: "",
        area: "",
        rentMode: "",
        rentalList: [],
        houseTypeList: [],
        directionList: [],
      },
      area: {
        /* 保存省市区查询列表 */ provinceList: [],
        cityList: [],
        areaList: [],
      },
      dict: {
        /* 保存字典数据 */ rentModeList: [],
        houseTypeList: [],
        directionList: [],
        rentalList: [],
      },
      editFormVisible: false,
      editForm: {
        /* 编辑表单展示数据，也是要提交到后台更新的数据 */ id: "",
        province: "",
        city: "",
        area: "",
        rentMode: "",
        houseType: "",
        direction: "",
        rental: "",
        address: "",
      },
      editArea: {
        cityList: [],
        areaList: [],
      },
    };
  },
  methods: {
    submitCode() {
      this.$http
        .put("/addCode", this.codes)
        .then((resp) => {
          console.log(resp);
          if (resp.data.code === 200) {
            this.dialogVisible = false;

            alert("添加成功");
            this.queryHouseList();
          } else {
            alert("失败");
            this.queryHouseList();
            this.dialogVisible = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    /* 查询房源列表数据 */
    queryHouseList() {
      this.$http
        .get("/queryCode", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((resp) => {
          this.houseList = resp.data.data.content;
          this.total = resp.data.data.total;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    /* 当页码改变时触发 */
    pageNumChange(currentPage) {
      this.pageNum = currentPage;
      this.queryHouseList();
    },

    /* 当每页显示记录改变时触发 */
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.queryHouseList();
    },

    // 查询所在省列表
    queryProvince() {
      this.$http
        .get("/area/-1")
        .then((resp) => {
          if (resp.data.code === 200) {
            this.area.provinceList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 查询所在市列表
    queryCity(provinceId) {
      /* form表单的数据清除 */
      this.queryForm.city = "";
      this.queryForm.area = "";

      /* 把所在市和所在区的列表数据清除 */
      this.area.cityList = [];
      this.area.areaList = [];

      if (provinceId) {
        this.$http("/area/" + provinceId)
          .then((resp) => {
            if (resp.data.code === 200) {
              this.area.cityList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    queryArea(cityId) {
      /* form表单的数据清除 */
      this.queryForm.area = "";
      /* 把和所在区的列表数据清除 */
      this.area.areaList = [];
      if (cityId) {
        this.$http
          .get("/area/" + cityId)
          .then((resp) => {
            if (resp.data.code === 200) {
              this.area.areaList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    queryDict(groupId) {
      // 租赁方式、户型、朝向、租金范围
      let url = "/dict/" + groupId;
      this.$http
        .get(url)
        .then((resp) => {
          if (groupId === "rent_mode") {
            this.dict.rentModeList = resp.data.data;
          } else if (groupId === "house_type") {
            this.dict.houseTypeList = resp.data.data;
          } else if (groupId === "direction") {
            this.dict.directionList = resp.data.data;
          } else {
            this.dict.rentalList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 展示编辑表单 */
    showEditForm(house) {
      this.editForm.id = house.id;
      this.editForm.province = house.province;

      this.$http
        .get("/area/" + house.province)
        .then((resp) => {
          if (resp.data.code === 200) {
            this.editArea.cityList = resp.data.data;
            /* 使下拉列表框选中所在市 */
            this.editForm.city = house.city;

            /* 给所在区赋值 */
            this.$http
              .get("/area/" + house.city)
              .then((resp) => {
                if (resp.data.code === 200) {
                  this.editArea.areaList = resp.data.data;
                  /* 使下拉列表框选中所在区 */
                  this.editForm.area = house.area;

                  this.editForm.rentMode = house.rentMode;
                  this.editForm.houseType = house.houseType;
                  this.editForm.direction = house.direction;
                  this.editForm.rental = house.rental;
                  this.editForm.address = house.address;

                  /* 赋值之后展示模态框 */
                  this.editFormVisible = true;
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 提交更新数据 */
    submitUpdate() {
      this.$http
        .put("/house", this.editForm)
        .then((resp) => {
          if (resp.data.code === 200) {
            this.$message.success("更新成功");

            // 更新成功之后重新查询数据
            this.queryHouseList();

            // 关闭模态框
            this.editFormVisible = false;
          } else {
            this.$message.error(resp.data.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteHouse(id) {
      this.$http
        .delete("/deleteCode/" + id)
        .then((resp) => {
          if (resp.data.code === 200) {
            this.$message.success("删除成功");
            this.queryHouseList();
          } else {
            this.$message.error(resp.data.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.queryHouseList();
  },
};
</script>